import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

import { Form, Field } from 'react-final-form';

import { Container, Row, Col, Button } from 'reactstrap';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';

import { 
  USERS_LIST, EDIT_USERS, ADD_USER, 
  ROLE_DROPDOWN, GENDER_LIST 
} from '../../config/config.js';

let initialValue = {
  firstName: '', lastName: '',
  email: '', password: '', address: '',
  mobile: '', gender: '', userType: '',
};

const UsersList = () => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);

  const [popup, setPopup] = useState(false);
  const [newId, setNewId] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [filter, setFilter] = useState('');

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  const [load, setLoad] = useState(false);
  const [dview, setDView] = useState(false);
  const [userType, setUType] = useState('');
  const [roles, setRoles] = useState([]);
  const [glist, setGList] = useState([]); 

  const addAdminUser = (obj, form) => {
    setLoad(true);

    axios.post(
      ADD_USER, obj, 
      {header: {token: sessionStorage.getItem('token')}}
    ).then((res) => {
      setPopup(false); getUsersList(page, filter, rows);
      setLoad(false); form.restart(); setOpen(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  const editAdminUser = (obj, form) => {
    setLoad(true);

    axios.put(
      EDIT_USERS + newId, 
      obj, 
      { header: { token: sessionStorage.getItem('token') } }
    ).then((res) => {
      setPopup(false); getUsersList(page, filter, rows);
      setLoad(false); form.restart(); setOpen(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleOnSubmit = (data, form) => {
    let obj = {
      FIRST_NAME: data.firstName,
      LAST_NAME: data.lastName,
      EMAIL: data.email,
      PASSWORD: data.password,
      GENDER_ID: data.gender,
      MOBILE_NUMBER: data.mobile,
      ADDRESS: data.address,
      USER_ROLE_ID: data.userType,
      UPDATED_BY: JSON.parse(sessionStorage.getItem('id')),
    };

    if(newId===''){
      obj.CREATED_BY = JSON.parse(sessionStorage.getItem('id')); 
      addAdminUser(obj, form);
    }else{
      editAdminUser(obj, form);
    }
  };

  const handleView = (e) => {
    setOpen(true); setUType(e.FIRST_NAME); 
    initialValue = {
      firstName: e.FIRST_NAME, lastName: e.LAST_NAME,
      mobile: e.MOBILE_NUMBER, email: e.EMAIL, gender: e.GENDER,
      password: e.PASSWORD, address: e.ADDRESS, userType: e.USER_ROLE,
    };
  };

  const handleEdit = (e) => {
    setUType(e.FIRST_NAME); setPopup(true); setNewId(e.ID); 
    initialValue = {
      firstName: e.FIRST_NAME, lastName: e.LAST_NAME,
      mobile: e.MOBILE_NUMBER, email: e.EMAIL, password: e.PASSWORD,
      gender: e.GENDER_ID, address: e.ADDRESS, userType: e.USER_ROLE_ID,
    };
  };
  
  const handleClickOpen = (e) => {
    setNewId(e.ID); setOpenPopup(true);
  };

  const handleCloseDelete = () => {
    setOpenPopup(false); 
  };
  
  const handleDelete = () => {
    axios({
      method: 'delete',
      url: EDIT_USERS + newId,
      'Content-Type': 'multipart/form-data',
      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      setOpenPopup(false); setNewId('');
      setLoad(true); getUsersList(page, filter, rows); 
      getUserRoles();
    });
  };
  
  const handleClose = () => {
    setUType(''); setPopup(false); 
    setOpen(false); initialValue = {
      firstName: '', lastName: '',
      email: '', password: '', address: '',
      mobile: '', gender: '', userType: '', 
    };
  };

  const pageCheck = () => {
    let list = JSON.parse(sessionStorage.getItem('roleJson'));
    setDView(!list.menu['adminusers'].enabled); 
  };

  useEffect(() => {
    getUserRoles(); pageCheck();
    getUsersList(page, filter, rows); 
  }, []);

  const getUsersList = (page, filter, row) => {
    axios({
      method: 'get',
      url: USERS_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
    }).then((res) => {
      if (res.data) {
        setTableData(res.data.rows); setLoad(false);
        setTRecords(res.data.total_count);
      } else {
        setLoad(false); setTRecords(0);
      }
    }).catch( (error) => {
      console.log(error);
    });
  };

  const getUserRoles=() =>{
    axios({
      method: 'get',
      url: ROLE_DROPDOWN,
    }).then((res) => {
      setRoles(res.data);
    }).catch((err) => {
      console.log(err);
    });

    axios({
      method: 'get',
      url: GENDER_LIST,
    }).then((res) => {
      setGList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const tableHeader=()=>{
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Users List</h3></div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder='Search...' />
       
        <Button variant="contained" className='table-button m-2' onClick={() => setPopup(true)}>
          <img src={Add} style={{ width: '25px', height: '20px', marginRight: 0.5 }} /> ADD
        </Button>
      </div>
    </div>
  };

  const actionHeader=(rowData)=>{
    return <div style={{display: 'flex', flexDirection:'row'}}>
      <img
        src={View} alt='View Image'
        onClick={() => handleView(rowData)}
        style={{width: '25px', height: '20px'}}
      />
    
      <img
        src={Edit} alt='Edit Image'
        style={{marginLeft: '12%', width: '25px', height: '20px'}}
        onClick={() => handleEdit(rowData)}
      />
    
      <img
        src={Delete} alt='Delete Image'
        style={{marginLeft: '10%', width: '25px', height: '20px'}}
        onClick={() => handleClickOpen(rowData)}
      />
    </div> 
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setPage(e.page); 
    setRows(e.rows); getUsersList(e.page, filter, e.rows);
  };

  const header = <h6>Pick a password</h6>;

  const footer = (
      <React.Fragment>
          <hr />

          <p className="mt-2">Suggestions</p>

          <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
            <li>At least one lowercase</li>
            <li>At least one uppercase</li>
            <li>At least one numeric-or-number</li>
            <li>Minimum 8 characters</li>
          </ul>
      </React.Fragment>
  );


  const nameBody=(row)=>{
    return row.LAST_NAME!==null ? <span>{row.FIRST_NAME+' '+ row.LAST_NAME}</span> : row.FIRST_NAME;
  };

  const validate = (data) => {
    let errors = {};

    if (!data.firstName) {
      errors.firstName = 'First Name is required.';
    }

    if (!data.lastName) {
      errors.lastName = 'Last Name is required.';
    }

    if (!data.mobile) {
      errors.mobile = 'Mobile Number is required.';
    } else if (/^[0-9]+@[0-9]+$/g.test(parseInt(data.mobile))) { 
      errors.mobile = 'Mobile Number must be number only.';
    } else if(!data.mobile.length < 10){
      errors.mobile = 'Mobile Number must be 10 digits only.'
    }

    if (!data.gender) {
      errors.gender = 'Gender is required.';
    }

    if (!data.userType) {
      errors.userType = 'User Type is required.';
    }

    if (!data.email) {
      errors.email = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = 'Invalid email address. E.g. example@email.com';
    }

    if (newId==='' && !data.password) {
      errors.password = 'Password is required.';
    }

    if (!data.address) {
      errors.address = 'Address is required.';
    }

    return errors;
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} removableSort globalFilter={filter} responsiveLayout="scroll"
          header={tableHeader} loading={load} scrollable size='medium' scrollHeight="64vh"
          disabled={!dview}
        >
          <Column header='Admin' field='FIRST_NAME' className=' text-truncate' body={nameBody} sortable />
          <Column header='Email' field='EMAIL' className=' text-truncate' style={{minWidth:'250px'}} sortable />
          <Column header='Mobile' field='MOBILE_NUMBER' className=' text-truncate' sortable />
          <Column header='User Role' field='USER_ROLE' className=' text-truncate' sortable />
          <Column header='Gender' field='GENDER' className=' text-truncate' sortable />
          <Column header='Actions' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />
      </div> 

      {/* Add / Edit Pop-up */}
      <Dialog
        visible={popup} onHide={handleClose} style={{width:'75vw', height:'auto'}}
        header={<div className='text-center'>
          {userType==='' ? 'Add User' : `Edit User - ${userType}`}
        </div>}
      >
        <Container>
          <Form 
            onSubmit={handleOnSubmit} 
            initialValues={ initialValue }
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={6}> 
                    <Field name='firstName' render={({ input, meta }) => ( <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>First Name *</label> 
                      <InputText {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} /> 
                      {getFormErrorMessage(meta)}
                    </> )} />
                  </Col>

                  <Col xs={12} md={6}> 
                    <Field name='lastName' render={({ input, meta }) => ( <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Last Name *</label> 
                      <InputText {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} /> 
                      {getFormErrorMessage(meta)}
                    </> )} />
                  </Col>

                  <Col xs={12} md={6} className='mt-1'> 
                    <Field name='email' render={({ input, meta }) => ( <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Email *</label> 
                      <InputText {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} /> 
                      {getFormErrorMessage(meta)}
                    </> )} />
                  </Col>

                  <Col xs={12} md={6} className='mt-1'>
                    <Field name='password' render={({ input, meta }) => ( <> 
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Password {newId===''? '*' : ""}</label> 
                      <Password {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} header={header} footer={footer} feedback={true} toggleMask /> 
                      {getFormErrorMessage(meta)}
                    </> )} />
                  </Col>

                  <Col xs={12} md={4} className='mt-1'> 
                    <Field name='mobile' render={({ input, meta }) => ( <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Mobile Number *</label> 
                      <InputMask {...input} style={{width: '100%'}} value={input.value}
                        className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                        mask='9999999999'
                      />
                      {getFormErrorMessage(meta)} 
                    </> )} />
                  </Col>
                  
                  <Col xs={12} md={4} className='mt-1'> 
                    <Field name='gender' render={({ input, meta }) => ( <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Gender *</label>
                      <Dropdown 
                        {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                        style={{width: '100%'}} options={glist} 
                        optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' 
                      /> 
                      {getFormErrorMessage(meta)}
                    </> )} />
                  </Col>

                  <Col xs={12} md={4} className='mt-1'>
                    <Field name='userType' render={({ input, meta }) => ( <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>User Type *</label>
                      <Dropdown  
                        {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                        style={{width: '100%'}} options={roles} 
                        optionLabel='NAME' optionValue='ID' 
                      /> 
                      {getFormErrorMessage(meta)}
                    </> )} />
                  </Col>

                  <Col xs={12} className='mt-1'> 
                    <Field name='address' render={({ input, meta }) => ( <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Address *</label> 
                      <InputTextarea 
                        {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                        style={{width: '100%'}} rows={5} cols={30} autoResize
                      /> 
                      {getFormErrorMessage(meta)}
                    </> )} />
                  </Col>

                  <Col xs={12} className='mt-2 text-center'>
                    <Button style={{
                      color: 'white', border: '0px solid', fontSize: '15px',
                      fontWeight: '400', width: '15vw', height: '7vh',
                      backgroundColor: 'rgb(0, 197, 205)',
                    }} onClick={handleSubmit}>Submit</Button>
                  </Col>
                </Row>
              </form>
            )} 
          />
        </Container>
      </Dialog>

      {/* View Pop-up */}
      <Dialog 
        visible={open} onHide={handleClose}
        style={{width: '50vw', height: '45vh'}}
        header={<div style={{textAlign: 'center'}}>{`User Details - ${userType}`}</div>}
      >
        <Container>
          <Row>
            <Col xs={3}>First Name</Col>
            <Col xs={1}>:</Col>
            <Col xs={8}>{initialValue.firstName}</Col>

            <Col xs={3}>Last Name</Col>
            <Col xs={1}>:</Col>
            <Col xs={8}>{initialValue.lastName}</Col>

            <Col xs={3}>Email</Col>
            <Col xs={1}>:</Col>
            <Col xs={8}>{initialValue.email}</Col>

            <Col xs={3}>Gender</Col>
            <Col xs={1}>:</Col>
            <Col xs={8}>{initialValue.gender}</Col>

            <Col xs={3}>User Type</Col>
            <Col xs={1}>:</Col>
            <Col xs={8}>{initialValue.userType}</Col>

            <Col xs={3}>Address</Col>
            <Col xs={1}>:</Col>
            <Col xs={8}>{initialValue.address}</Col>
          </Row>
        </Container>
      </Dialog>

      {/* Delete Pop-up */}
      <Dialog
        visible={openPopup} onHide={handleCloseDelete}
        header={<div style={{textAlign: 'center'}}>You want to delete the record ?</div>}
      >
        <div className='text-center'> 
          <Button style={{marginRight: '5%'}} onClick={handleCloseDelete}>No</Button>
          <Button onClick={handleDelete} autoFocus>Yes</Button>
        </div>       
      </Dialog>

      <Dialog
        visible={dview} header={<div style={{ textAlign: 'center' }}>
          Not Authorized
        </div>} onHide={() => {
          window.location.href='/home'; setDView(false)
        }}
      >
        <div style={{textAlign: 'center'}}>
          <Button onClick={() => window.location.href='/home'}>Home</Button>
        </div>
      </Dialog>
    </div>
  );
};

export default UsersList;
import React, { useState, useEffect, Fragment } from 'react';

import axios from 'axios';

import { Tag } from 'primereact/tag';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
// import { Checkbox } from 'primereact/checkbox';
import { Paginator } from 'primereact/paginator';
import { InputTextarea } from 'primereact/inputtextarea';

import { Container, Row, Col, Button } from 'reactstrap';

import View from '../icons/view.svg';
import Truck from '../icons/delivery.svg';
import Close from '../icons/order_del.svg';
import Info from '../Sidebar/icons/info.svg';
import Settings from '../icons/settings.svg';
import Filter from '../Sidebar/icons/filter.svg';

import Mobile from '../icons/mobile.svg';
import Website from '../icons/website.svg';

import { 
  ORDER_DELIVERY, ORDER_DELIVERY_LIST, ORDERS,
  ORDER_LIST, ORDER_ITEMS, IMAGE_DOMAIN, CHANGE_ORDER_STATUS,
} from '../../config/config.js';
import './order.css';

export default function OrderList(){
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [filter, setFilter] = useState('');
  const [load, setLoad] = useState(false);
  const [products, setPrds] = useState(null);
  const [selObj, setSObj] = useState('');
  const [viewObj, setVObj] = useState([]);
  const [orderID, setOId] = useState('');
  // const [change, setChng] = useState('');
  // const [status, setStatus] = useState('');
  const [expandRow, setExpandRow] = useState(null);
  // const [delat, setDAt] = useState(moment(new Date()));
  const [delAdd, setDAdd] = useState('');
  const [edata, setEData] = useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [First, setFirst] = useState(0);
  const [Rows, setRows] = useState(10);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [Page, setPage] = useState(0);

  const [width, setWidth] = useState('');
  const [length, setLength] = useState('');
  const [breadth, setBreadth] = useState('');
  const [height, setHeight] = useState('');
  const [pickUpLoc, setPLoc] = useState('');
  const [shipping, setShip] = useState('');
  const [cod, setCod] = useState('');
  const [discount, setDiscnt] = useState('');
  const [OrderAmnt, setOAmnt] = useState('');
  const [cAmnt, setCAmnt] = useState('');
  const [delivery, setDelivery] = useState('');
  const [dName, setDName] = useState('');
  const [orderDelList, setODList] = useState([]);
  const [cOpen, setCOpen] = useState(false);
  const [err, setErr] = useState(false);
  const [chngOpn, setCOpn] = useState(false); 
  const [orderChn, setOChn] = useState('');

  const mainId = sessionStorage.getItem('id');

  let today;  

  const getOrderListData = (page, filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: ORDER_LIST + '&limit='+row+'&skip='+(page+1)+(filter!=='' ? '&search='+filter : '&search='),
    }).then((res) => {
      setTotalRecords(res.data.total_count);
      setTableData(res.data.rows); setLoad(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getOrderDelivery = () => {
    axios({
      method: 'get',
      url: ORDER_DELIVERY_LIST,
    }).then((res) => {
      setODList(res.data);
    }).catch((err) => {
      console.log(err);
    })
  };

  useEffect(() => {
    getOrderListData(Page, filter, Rows);
  }, [filter]);

  const onPageChange = (e) => {
    setFirst(e.first); setPage(e.page); setRows(e.rows); 
    getOrderListData(e.page, filter, e.rows);
  };

  const tableHeader = () => {
    return <div className='DataTable'>
      <div className='m-2'>
        <h3> Order List</h3>
      </div>

      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search Order id.' />

        <Button variant="contained" className="table-button m-2" onClick={() => setEData(true)} disabled={true}>
          <img src={Filter} style={{ width: '25px', height: '20px', marginRight: 0.5 }} /> Filter
        </Button>
      </div>
    </div>
  };

  const timeConvert = (d) => {
    let ds = new Date(d.CREATED_AT); 

    return ds.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const updateTime = (d) => {
    let ds = new Date(d.UPDATED_AT); 

    return ds.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const handleView = (rowData) => {
    setOId(rowData.ORDER_TRACKER_ID);

    axios({
      method: 'get',
      url: ORDER_ITEMS + rowData.ORDER_TRACKER_ID,
    }).then((res) => {
      setPopup(true); setPrds(res.data.rows);
    }).catch((err) => {
      console.log(err);
    });

    axios({
      method: 'get',
      url: ORDERS + rowData.ID,
    }).then((res) => {
      setSObj(res.data); setVObj(res.data.ORDER_HISTORY);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleEdit = (rowData) => {
    getOrderListData(Page, filter, Rows); getOrderDelivery();

    let sobj =  tableData.filter((it) => it.ORDER_TRACKER_ID === rowData.ORDER_TRACKER_ID);

    setOId(sobj[0].ORDER_TRACKER_ID); setOAmnt(parseInt(rowData.ORDER_TOTAL_AMOUNT));

    setOpen(true);
  };

  const handleChngs = (row) => {
    setCOpn(true); setSObj(row);
    setDAdd(row.DELIVERY_ADDRESS);
  };

  const actionHeader = (rowData) => {
    return <div style={{ display: 'flex', flexDirection: 'row' }}>
      <img
        src={View} alt='View Image'
        style={{ marginRight: '18px', width: '25px', height: '20px' }}
        onClick={() => handleView(rowData)}
      />
      
      {(rowData.STATUS!=='DELIVERED' && rowData.STATUS!=='CANCELLED' && rowData.DELIVERY_TYPE!=='STORE_PICKUP') && <img
        src={Truck} alt='Delivery Image'
        onClick={() => handleEdit(rowData)}
        style={{ width: '25px', height: 'auto', marginTop: '-2.5px', marginRight: '18px' }}
      />}

      {(rowData.STATUS!=='DELIVERED' && rowData.STATUS!=='CANCELLED' && rowData.STATUS!=='PICKED_UP') && <img 
        src={Close} alt='Cancel Order Image'
        onClick={() => handleCancel(rowData)}
        style={{ width: '21px', height: 'auto' }}
      />}

      {(rowData.STATUS!=='DELIVERED' && rowData.STATUS!=='CANCELLED' && rowData.STATUS!=='PICKED_UP') && <img
        src={Settings} alt='Settings Image'
        style={{ marginLeft: '15px', width: '21px', height: 'auto', marginTop: '2px' }}
        onClick={() => handleChngs(rowData)}
      />}
    </div>
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <h5 className='m-2'>Order Details - {data.ORDER_TRACKER_ID}</h5>
        <Container className='m-2'>
          <Row>
            <Col xs={6}> 
              <div className='font_sm'>
                <span className='sub_heading'>Customer Name :</span>
                {data.LAST_NAME !== '' ? data.FIRST_NAME + ' ' + data.LAST_NAME : data.FIRST_NAME}
              </div> 
            </Col>

            <Col xs={6}> 
              <div className='font_sm'>
                <span className='sub_heading'>Order Status :</span>
                {data.STATUS === "ORDERED" && <span className='color_blue'>Ordered</span>}
                {data.STATUS === "DELIVERED" && <span className='color_success'>Delivered</span>}
                {data.STATUS === "DISPATCHED" && <span  className='color_warning'>Dispatched</span>}
                {data.STATUS === "PREBOOK" && <span  className='color_secondary'>Pre-book</span>}
                {data.STATUS === "CANCELLED" && <span  className='color_danger'>Cancelled</span>}
              </div> 
            </Col>

            <Col xs={6}> 
              <div className='font_sm'>
                <span className='sub_heading'>Order Amount :</span> 
                {data.ORDER_TOTAL_AMOUNT && new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.ORDER_TOTAL_AMOUNT)}&nbsp;
              </div> 
            </Col>

            <Col xs={6}> 
              <div className='font_sm'>
                <span className='sub_heading'>Order Date :</span> 
                {timeConvert(data)}
              </div> 
            </Col>
            
            <Col xs={6}> 
              <div className='font_sm'>
                <span className='sub_heading'>Payment Status :</span> 
                {data.PAYMENT_STATUS === "PENDING" && <span className='color_warning'>Pending</span>}
                {data.PAYMENT_STATUS === "FAILED" && <span className='color_danger'>Failed</span>}
                {data.PAYMENT_STATUS === "SUCCESS" && <span className='color_success'>Success</span>}
              </div> 
            </Col>

            {data.COUPON_CODE !== null ? <Fragment>
              <Col xs={6}> 
                <div className='font_sm'>
                  <span className='sub_heading'>Payment Mode :</span> 
                  {data.PAYMENT_MODE === 'COD' && <span className='color_warning'>Cash On Delivery</span>}
                  {data.PAYMENT_MODE === 'ATUM_WALLET' && <span className='color_success'>ATUM Wallet</span>}
                  {data.PAYMENT_MODE === 'ONLINE' && <span className='color_success'>Payment Online</span>}
                </div> 
              </Col>

              <Col xs={6}> 
                <div className='font_sm'>
                  <span className='sub_heading'>Coupon Code :</span> 
                  <span style={{fontWeight: '800'}}>{data.COUPON_CODE}</span>
                </div> 
              </Col>

              <Col xs={6}>
                <div className='font_sm'>
                  <span className='sub_heading'>Coupon Amount :</span> 
                  <span style={{fontWeight: '800'}}>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.COUPON_DISCOUNTED_AMOUNT)}</span>
                </div> 
              </Col>
            </Fragment> : <Col xs={6}> 
              <div className='font_sm'>
                <span className='sub_heading'>Payment Mode :</span> 
                {data.PAYMENT_MODE === 'COD' && <span className='color_warning'>Cash On Delivery</span>}
                {data.PAYMENT_MODE === 'ATUM_WALLET' && <span className='color_success'>ATUM Wallet</span>}
                {data.PAYMENT_MODE === 'ONLINE' && <span className='color_success'>Payment Online</span>}
              </div> 
            </Col>}

            <Col xs={6}>
              <div className='font_sm'>
                <span className='sub_heading'>Platform :</span> 
                {data.PLATFORM==='MOBILE' ? <span>
                  Mobile <img src={Mobile} style={{width: '25px', height: '20px'}} />
                </span> : <span>
                  Website <img src={Website} style={{width: '25px', height: '20px'}} />
                </span>}
              </div> 
            </Col>

            {parseFloat(data.PREBOOK_AMOUNT) > 0 && <Col xs={6}>
              <div className='font_sm'>
                <span className='sub_heading'>PreBook Amount :</span> 
                <span style={{fontWeight: '800'}}> {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.PREBOOK_AMOUNT)}</span>
              </div>
            </Col>}

            {parseFloat(data.DELIVERY_CHARGES) > 0 && <Col xs={6}>
              <div className='font_sm'>
                <span className='sub_heading'>Delivery Charges :</span> 
                <span style={{fontWeight: '600'}}> {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data.DELIVERY_CHARGES)}</span>
              </div>
            </Col>}
            
            <Col xs={6}> 
              <div className='font_sm'>
                <span className='sub_heading'>Delivery Address :</span> 
                {data.DELIVERY_ADDRESS}
              </div> 
            </Col>

            {data.DELIVERED_AT ? <Col xs={6}>
              <div className='font_sm'>
                <span className='sub_heading'>Estimated Delivery Date :</span> 
                {dateBody2(data.DELIVERED_AT)}
              </div> 
            </Col> : ''}
          </Row>
        </Container>
      </div>
    )
  };

  const imgHeader = (rowData) => {
    return <Image src={IMAGE_DOMAIN + rowData.IMAGE_PATH} preview width='100%' height='25px' />
  };

  const orderStatus = (rowData) => {
    return <div className='status_button'>
      {rowData.STATUS === "PREBOOK" && <Button value="ORDER PLACED" className="order_placed secondary ordSts" variant="outlined"> PRE BOOK PLACED</Button>}
      {rowData.STATUS === "ORDERED" && <Button value="ORDER PLACED" className="order_placed primary ordSts" variant="outlined"> ORDER PLACED</Button>}
      {rowData.STATUS === "DELIVERED" && <Button variant="outlined" value="DELIVERED" className="delivered success ordSts">DELIVERED </Button>}
      {rowData.STATUS === "DISPATCHED" && <Button variant="outlined" value="DELIVERED" className="dispatched warning ordSts"> DISPATCHED </Button>}
      {rowData.STATUS === "CANCELLED" && <Button variant="outlined" value="DELIVERED" className="cancelled danger ordSts"> CANCELLED</Button>}
      {rowData.STATUS === "READY_TO_DISPATCH" && <Button variant="outlined" value="DELIVERED" className="dispatched warning ordSts"> READY TO DISPATCH </Button>}
      {rowData.STATUS === "READY_TO_PICKUP" && <Button variant="outlined" value="DELIVERED" className="dispatched warning ordSts"> READY TO PICKUP </Button>}
      {rowData.STATUS === "PICKED_UP" && <Button variant="outlined" value="DELIVERED" className="delivered success ordSts">PICKED UP </Button>}
    </div>
  };

  const paymentStatus = (rowData) => {
    return <div className='status_button'>
      {rowData.PAYMENT_MODE === 'COD' && <Button variant="contained" label="Cash On Delivery" className="cod contained_primary w130" > Cash On Delivery</Button>}
      {rowData.PAYMENT_MODE === 'ATUM_WALLET' && <Button variant="contained" label='ATUM Wallet' className="wallet contained_success w130"> ATUM Wallet</Button>}
      {rowData.PAYMENT_MODE === 'ONLINE' && <Button variant="contained" label='Payment Online' className="pay-online contained_success w130"> Payment Online</Button>}
    </div>
  };

  // const onCityChange = (e) => {
  //   let selectedCities = [];

  //   if (e.checked) {
  //     selectedCities.push(e.value);
  //   } else {
  //     selectedCities.splice(selectedCities.indexOf(e.value), 1);
  //   };

  //   setStatus(selectedCities);
  // };

  const handleCancel = (row) => {
    setSObj(row); setCOpen(true);
  };

  const cancelOrder = () => {
    let data = {
      USER_ID: selObj.USER_ID,
      DELIVERY_ADDRESS: selObj.DELIVERY_ADDRESS,
      ORDER_TOTAL_AMOUNT: selObj.ORDER_TOTAL_AMOUNT,
      ORDER_TRACKER_ID: selObj.ORDER_TRACKER_ID,
      ACTION: 'ORDER_CANCELLATION',
      STATUS: 'CANCELLED',
    };

    axios({
      method: 'PUT',
      url: ORDERS + selObj.ID,
      data: data,
    }).then((res) => {
      setSObj(null); setCOpen(false);
      // setChng(''); setStatus(''); setDAdd(''); setDAt('');
      getOrderListData(Page, filter, Rows);
    }).catch((err) => {
      console.log(err);
    });
  };

  const chngStatus = () => {
    if(dName==='OUR_DELIVERY'){
      if(pickUpLoc!=='' && OrderAmnt!=='' && delivery!==''){
        // DELIVERED_AT: new Date(delat),
        // ACTION: 'ORDER_STATUS',
        // DELIVERY_ADDRESS: delAdd,
        // STATUS: status[0],
        let datas = {
          WEIGHT: parseInt(width ? width : 0),
          LENGTH: parseInt(length ? length : 0),
          BREADTH: parseInt(breadth ? breadth : 0),
          HEIGHT: parseInt(height ? height : 0),
          PICKUP_LOCATION: pickUpLoc,
          SHIPPING_CHARGES: parseInt(shipping ? shipping : 0),
          COD_CHARGES: parseInt(cod ? cod : 0),
          DISCOUNT: parseInt(discount ? discount : 0),
          ORDER_AMOUNT: parseInt(OrderAmnt),
          COLLECTABLE_AMOUNT: parseInt(cAmnt ? cAmnt : 0),
          DELIVERY_PROVIDER_NAME: dName,
          DELIVERY_PROVIDER_ID: delivery,
          ORDER_TRACKER_ID: orderID,
        }; 

        axios({
          method: 'post',
          url: ORDER_DELIVERY,
          data: datas,
        }).then((res) => {
          setSObj(null); setOpen(false); setOId(''); 

          setBreadth(''); setCAmnt(''); setCod(''); setDName('');
          setDiscnt(''); setLength(''); setHeight(''); setPLoc('');
          setDelivery(''); setWidth(''); setOAmnt(''); setShip('');
          // setDAt(''); setChng(''); setStatus(''); setDAdd('');
          getOrderListData(Page, filter, Rows); setErr(false);
        }).catch((err) => {
          console.log(err);
        }); 
      }else{
        setErr(true);
      }
    }else{
      if(width!=='' && height!=='' && length!=='' && breadth!=='' && pickUpLoc!=='' && OrderAmnt!=='' && cAmnt!=='' && delivery!==''){
        // DELIVERED_AT: new Date(delat),
        // ACTION: 'ORDER_STATUS',
        // DELIVERY_ADDRESS: delAdd,
        // STATUS: status[0],
        let datas = {
          WEIGHT: parseInt(width),
          LENGTH: parseInt(length),
          BREADTH: parseInt(breadth),
          HEIGHT: parseInt(height),
          PICKUP_LOCATION: pickUpLoc,
          SHIPPING_CHARGES: parseInt(shipping),
          COD_CHARGES: parseInt(cod),
          DISCOUNT: parseInt(discount),
          ORDER_AMOUNT: parseInt(OrderAmnt),
          COLLECTABLE_AMOUNT: parseInt(cAmnt),
          DELIVERY_PROVIDER_NAME: dName,
          DELIVERY_PROVIDER_ID: delivery,
          ORDER_TRACKER_ID: orderID,
        }; 

        axios({
          method: 'post',
          url: ORDER_DELIVERY,
          data: datas,
        }).then((res) => {
          setSObj(null); setOpen(false); setOId(''); 

          setBreadth(''); setCAmnt(''); setCod(''); setDName('');
          setDiscnt(''); setLength(''); setHeight(''); setPLoc('');
          setDelivery(''); setWidth(''); setOAmnt(''); setShip('');
          // setDAt(''); setChng(''); setStatus(''); setDAdd('');
          getOrderListData(Page, filter, Rows); setErr(false);
        }).catch((err) => {
          console.log(err);
        }); 
      }else{
        setErr(true);
      }
    };
  };

  const handleXData = () => {

  };

  const nameBody = (row) => {
    return row.LAST_NAME !== '' ? row.FIRST_NAME + ' ' + row.LAST_NAME : row.FIRST_NAME;
  };

  const dateBody = (row) => {
    let ds = new Date(row.CREATED_AT);

    return ds.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const dateBody2 = (dt) => {
    let ds = new Date(dt);

    return ds.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const colorBody = (row) => {
    return <div>{row.PRODUCT_COLOR ? <div style={{ backgroundColor: row.PRODUCT_COLOR, width: '45px', height: '100%', borderRadius: '25px' }}>&nbsp;</div>  : '-'}</div>
  };

  const handleClose = () => {
    setSObj(''); setOpen(false); setOId(''); 

    setBreadth(''); setCAmnt(''); setCod(''); setDName('');
    setDiscnt(''); setLength(''); setHeight(''); setPLoc('');
    setDelivery(''); setWidth(''); setOAmnt(''); setShip('');
    // setDAt(''); setChng(''); setStatus(''); setDAdd('');
    getOrderListData(Page, filter, Rows); setErr(false);
  };

  const extraChargeBody = (row) => {
    return row.extraCharges ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.extraCharges) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(0);
  };

  const orderAmntBody = (row) => {
    return row.ORDER_TOTAL_AMOUNT ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.ORDER_TOTAL_AMOUNT) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(0)
  };

  const priceBody = (row) => {
    return row.PRICE ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.PRICE) : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(0)
  };

  const amountInfo = (row) => {
    return(
      <Container>
        <Row style={{ width: '22vw' }}>
          {products && products.map((it, idx) => 
            <>
              <Col xs={6} style={{textAlign: 'left', fontSize: '13px'}}>Product {idx+1}</Col> 

              <Col xs={6} style={{textAlign: 'right', fontSize: '12px'}}> {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(it.PRICE)} + {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(it.extraCharges)}</Col>
            </>
          )}

          <Col xs={6} style={{textAlign: 'left', fontSize: '13px'}}>Total Order Amount </Col> 

          <Col xs={6} style={{textAlign: 'right', fontSize: '12px'}}> {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.ORDER_TOTAL_AMOUNT)}</Col>
        </Row>
      </Container>
    )
  };

  const handleDeliveryName = (val) => {
    let arr = orderDelList.filter((itm) => itm.ID === val);

    setDName(arr[0].LOOKUP_NAME);
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const deliveryBody = (row) => {
    return row.DELIVERY_TYPE==='STORE_PICKUP' ? <div style={{color: 'green', fontWeight: '600'}}>Store Pickup</div> : <div style={{color: 'dodgerblue', fontWeight: '600'}}>Home Delivery</div>
  };

  const statusUpdateBody = (row) => {
    return <div style={{textAlign: 'center'}}>
      {row.STATUS_UPDATED === 0 ? 'Pending' : updateTime(row)}
    </div>
  };

  const changeOrder = () => {
    axios({
      method: 'patch',
      url: CHANGE_ORDER_STATUS,
      data: {
        "STATUS": orderChn,
        "UPDATED_BY": mainId,
        "ORDER_TRACKER_ID": selObj.ORDER_TRACKER_ID,
      }
    }).then((res) => {
      console.log(res.data);

      setCOpn(false); 
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div className="pt-4 m-3">
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable
          value={tableData} removableSort onPage={onPageChange} loading={load} scrollable scrollHeight="64vh"
          globalFilter={filter} header={tableHeader} responsiveLayout="scroll" onRowToggle={(e) => setExpandRow(e.data)}
          rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandRow}
        >
          <Column expander style={{maxWidth:'50px'}} />
          <Column header='Order ID' field='ORDER_TRACKER_ID' className='text-truncate' sortable />
          <Column header='Name' field='FIRST_NAME' className='text-truncate text-name' body={nameBody} sortable style={{minWidth: '75px'}} />
          <Column header='Ordered Date' field='CREATED_AT' className='text-truncate' body={dateBody} sortable />
          <Column header='Payment Mode' field='PAYMENT_MODE' className='text-truncate' style={{minWidth:'140px'}} body={paymentStatus} sortable />
          <Column header='Delivery Type' field='DELIVERY_TYPE' className='text-truncate' body={deliveryBody} sortable />
          <Column header='Order Status' field='STATUS' className=' text-truncate' style={{minWidth:'170px'}} body={orderStatus} sortable />
          <Column header='Action' body={actionHeader} />
        </DataTable>
        <Paginator rows={Rows} totalRecords={TotalRecords} first={First} onPageChange={onPageChange} template={pageTemplate} />
      </div>

      {/* View Order Details */}
      <Dialog
        visible={popup} className="heading_dialog"
        onHide={() => setPopup(false)} maximizable
        header={<div style={{textAlign: 'center'}}>Order Details - <span style={{fontWeight: '400'}}>{orderID}</span></div>}
      >
        {selObj !== null && <Container style={{marginTop: '1%'}}>
          <Row>
            <Col xs={6}> 
              <div style={{marginTop: '0.3%'}}>
                <span style={{fontWeight: '700'}}>Customer Name -</span>&nbsp;
                {selObj.LAST_NAME!=='' ? selObj.FIRST_NAME+' '+selObj.LAST_NAME : selObj.FIRST_NAME}
              </div> 
            </Col>

            <Col xs={6}> 
              <div style={{marginTop: '0.3%'}}>
                <span style={{fontWeight: '700'}}>Order Status -&nbsp;</span> 
                {selObj.STATUS === "ORDERED" && 
                  <span style={{color:'dodgerblue', fontWeight: 'bold'}}>
                    Ordered
                  </span>}
                  {selObj.STATUS === "PREBOOK" && 
                    <span style={{color:'#282c34', fontWeight: 'bold'}}>
                      Pre Book
                    </span>}
                  {selObj.STATUS === "DELIVERED" && 
                    <span style={{color: 'darkgreen', fontWeight: 'bold'}}>
                      Delivered
                    </span>}
                  {selObj.STATUS === "PICKED_UP" && 
                    <span style={{color: 'darkgreen', fontWeight: 'bold'}}>
                      Picked Up
                    </span>}
                  {selObj.STATUS === "DISPATCHED" && 
                    <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                      Dispatched
                    </span>}
                  {selObj.STATUS === "READY_TO_DISPATCH" && 
                    <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                      Ready to Dispatch
                    </span>}
                  {selObj.STATUS === "READY_TO_PICKUP" && 
                    <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                      Ready to Pickup
                    </span>}
                  {selObj.STATUS === "CANCELLED" && 
                    <span style={{color: 'red', fontWeight: 'bold'}}>
                      Cancelled
                    </span>}
              </div> 
            </Col>

            <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>
                  Order Amount -
                </span> 
                &nbsp;{selObj.ORDER_TOTAL_AMOUNT && new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(selObj.ORDER_TOTAL_AMOUNT)} &nbsp;

                <Tooltip target=".logoOrder">
                  {amountInfo(selObj)}    
                </Tooltip>

                <img src={Info} className='logoOrder' style={{ width: '25px', height: '20px', width: '10px', height: '10px', borderRadius: '45px', padding: '3px', border: '1.5px solid'  }} />
              </div> 
            </Col>

            <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>
                  Order Date -&nbsp;
                </span> 
                {timeConvert(selObj)}
              </div> 
            </Col>

            <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>
                  Payment Status -&nbsp;
                </span> 
                {selObj.PAYMENT_STATUS === "PENDING" && 
                  <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                    Pending
                  </span>}
                {selObj.PAYMENT_STATUS === "FAILED" && 
                  <span style={{color: 'red', fontWeight: 'bold'}}>
                    Failed
                  </span>}
                {selObj.PAYMENT_STATUS === "SUCCESS" && 
                  <span style={{color: 'darkgreen', fontWeight: 'bold'}}>
                    Success
                  </span>}  
              </div>
            </Col>

            <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>
                  Payment Mode -&nbsp;
                </span> 
                {selObj.PAYMENT_MODE === 'COD' && 
                  <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                    Cash On Delivery
                  </span>}
                {selObj.PAYMENT_MODE === 'ATUM_WALLET' && 
                  <span style={{color: 'darkgreen', fontWeight: 'bold'}}>
                    ATUM Wallet
                  </span>}
                {selObj.PAYMENT_MODE === 'ONLINE' && 
                  <span style={{color: 'darkgreen', fontWeight: 'bold'}}>
                    Payment Online
                  </span>}
              </div> 
            </Col>

            <Col xs={6}>
              <div style={{
                marginTop: '1%'
              }}>
                <span style={{fontWeight: '700'}}>
                  Platform  -&nbsp;
                </span> 

                {selObj.PLATFORM === 'MOBILE' && <span style={{fontWeight: 'bold'}}>
                  <img src={Mobile} style={{width: '20px', height: '25px'}} /> Mobile
                </span>}

                {selObj.PLATFORM === 'WEBSITE' && <span style={{fontWeight: 'bold'}}>
                  <img src={Website} style={{width: '20px', height: '25px'}} /> Website
                </span>}
              </div>
            </Col>
            
            {selObj.COUPON_CODE!==null && <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Coupon Applied -&nbsp;</span> 
                <span style={{fontWeight:'600'}}>{selObj.COUPON_CODE}</span>
              </div>
            </Col>}

            {(selObj.COUPON_CODE!==null && parseFloat(selObj.COUPON_DISCOUNTED_AMOUNT) > 0) ? <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Coupon Applied Amount -&nbsp;</span> 
                <span style={{fontWeight:'600'}}>₹ {selObj.COUPON_DISCOUNTED_AMOUNT}</span>
              </div>
            </Col> : ''}
            
            {parseFloat(selObj.PREBOOK_AMOUNT) > 0 && <Col xs={6}>
              <div className='font_sm' style={{marginTop: '1%'}}>
                <span className='sub_heading'>PreBook Amount -&nbsp;</span> 
                <span style={{fontWeight: '800'}}><span style={{fontWeight: '500'}}>₹</span> {selObj.PREBOOK_AMOUNT}</span>
              </div>
            </Col>}
            
            {parseFloat(selObj.DELIVERY_CHARGES) > 0 && <Col xs={6}>
              <div className='font_sm' style={{marginTop: '1%'}}>
                <span className='sub_heading'>Delivery Charges -&nbsp;</span> 
                <span style={{fontWeight: '600'}}>{selObj.DELIVERY_CHARGES}</span>
              </div>
            </Col>}

            {selObj.DELIVERED_AT!==null && <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Excepted Delivery -&nbsp;</span> 
                <span>&nbsp;{dateBody2(selObj.DELIVERED_AT)}</span>
              </div> 
            </Col>}

            {(selObj.UPDATED_AT!==null && selObj.STATUS === "CANCELLED") && <Col xs={6}>
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Order Cancelled Date -&nbsp;</span>
                <span>&nbsp;{dateBody2(selObj.UPDATED_AT)}</span>
              </div>
            </Col>}
            
            <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Delivery Type -&nbsp;</span> {selObj.DELIVERY_TYPE && selObj.DELIVERY_TYPE==='STORE_PICKUP' ? <span>Store Pickup</span> : <span>Home Delivery</span>}
              </div> 
            </Col>

            <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Delivery Instructions -&nbsp;</span> {selObj.DELIVERY_INSTRUCTIONS ? selObj.DELIVERY_INSTRUCTIONS : 'None .'}
              </div> 
            </Col>

            {selObj.STORE_ADDRESS && <Col xs={6}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Store Address -&nbsp;</span> {selObj.STORE_ADDRESS ? selObj.STORE_ADDRESS+','+selObj.STORE_CONTACT_NUMBER : 'None .'}
              </div> 
            </Col>}

            {selObj.DELIVERY_ADDRESS && <Col xs={12}> 
              <div style={{marginTop: '1%'}}>
                <span style={{fontWeight: '700'}}>Delivery Address -&nbsp;</span> {selObj.DELIVERY_ADDRESS}
              </div> 
            </Col>}
          </Row>
        </Container>}

        {(viewObj.length>=1) ? <Fragment>
          <h5 style={{ borderTop: '1px dashed', marginTop: '0.5%', paddingLeft: '1%', paddingTop: '2px' }}>Order History</h5>

          <DataTable value={viewObj} removableSort>
            <Column header='Order ID' className='text-truncate minw170' field='ORDER_TRACKER_ID' sortable />
            <Column header='Order Amount' className='text-truncate' field='ORDER_TOTAL_AMOUNT' body={orderAmntBody} />
            <Column header='Order Status' className='text-truncate' field='STATUS' body={orderStatus} />
            <Column header='Order Status Update' className='text-truncate' field='STATUS_UPDATED' body={statusUpdateBody} />
            <Column header='Payment Mode' className='text-truncate' field='PAYMENT_MODE' sortable />
            <Column header='Payment Status' className='text-truncate' field='PAYMENT_STATUS' sortable />
            {/* <Column header='Transaction Date' className='text-truncate' field='CREATED_AT' sortable body={dateBody} /> */}
          </DataTable> 
        </Fragment> : ''}

        <h5 style={{ borderTop: '1px dashed', marginTop: '1%', paddingLeft: '1%', paddingTop: '2px' }}>Order Products List</h5>
        
        <DataTable value={products} removableSort>
          <Column header='Product Name' className=' text-truncate minw170' field='PRODUCT_NAME' sortable />
          <Column header='Price' field='PRICE' className=' text-truncate' body={priceBody} sortable />
          <Column header='Quantity' field='QUANTITY' className=' text-truncate' sortable />
          <Column header='Extra Charges' field='extraCharges' className=' text-truncate' sortable body={extraChargeBody} />
          <Column header='Color' body={colorBody} />
          <Column header='Image' body={imgHeader} />
        </DataTable>
      </Dialog>

      {/* Delivery Partner popup */}
      <Dialog
        visible={open} style={{width: '65vw'}}
        className="heading_dialog" onHide={handleClose}
        header={<div>Order - <span style={{fontWeight: '400'}}>{orderID}</span></div>}
        footer={<div className='status_button text-center'>
          {/* <Button variant="contained" label='Cancel Order' className='contained_danger' onClick={cancelOrder}> Cancel Order </Button> */}
          <Button variant="contained" className='contained_success' onClick={() => chngStatus()}>
            <div style={{fontSize: '15px'}}>Submit</div>
          </Button>
        </div>}
      >
        {/* <h6> Order Status </h6> 
          <div className='d-flex'>
            {(change === 'ORDERED' || change === 'PREBOOK') && <div className="field-checkbox">
              <Checkbox inputId="city1" name="city" value="DISPATCHED" color='success' onChange={onCityChange} checked={status.indexOf('DISPATCHED') !== -1} disabled={change === 'PREBOOK' ? true : false} />
              <label htmlFor="city1" className='mx-2'>Dispatch</label>
            </div>}

            {(change === 'DISPATCHED' || change === 'ORDERED' || change === 'PREBOOK') && <div className="field-checkbox mx-2">
              <Checkbox inputId="city2" name="city" value="DELIVERED" onChange={onCityChange} checked={status.indexOf('DELIVERED') !== -1} disabled={change === 'PREBOOK' ? true : false} />
              <label htmlFor="city2" className='mx-2'>Delivery</label>
            </div>}
          </div>

          <div className='mt-3'>
            <h6 htmlFor="chngadd"> Change Address </h6>

            {(status.indexOf('DISPATCHED') !== -1) ? <InputTextarea
              inputId="chngadd" rows={4} cols={40} value={delAdd} 
              onChange={(e) => setDAdd(e.target.value)}
              style={{width: '100%', height: '14vh'}}
            /> : <InputTextarea inputId="chngadd"
              rows={5} cols={40} value={delAdd}
              onChange={(e) => setDAdd(e.target.value)}
              style={{width: '100%', height: '14vh'}}
            />}
          </div>

          {(status.indexOf('DISPATCHED') !== -1) && <div>
            <h6> Delivery Estimate Date </h6>

            <Calendar 
              value={delat} onChange={(e) => setDAt(e.value)}
              style={{ height: '35px' }} showIcon dateFormat="dd/mm/yy"
              minDate={new Date()}
            />
        </div>} */}

        <Container>
          <Row>
            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&delivery==='') ? 'p-invalid' : ''}>Delivery Provider</label>
              <Dropdown 
                style={{width: '100%'}} value={delivery} 
                onChange={(e) => {setDelivery(e.value); handleDeliveryName(e.value)}} 
                options={orderDelList} optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' 
              />
              {(err===true&&delivery==='') && <small style={{color: 'red'}}>Delivery Provider is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&width==='') ? 'p-invalid' : ''}>Weight</label>
              <InputText 
                style={{width: '100%'}} value={width} 
                onChange={(e) => setWidth(e.target.value)} 
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {dName!=='OUR_DELIVERY' && (err===true&&width==='') && <small style={{color: 'red'}}>Width is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&length==='') ? 'p-invalid' : ''}>Length</label>
              <InputText 
                style={{width: '100%'}} value={length} 
                onChange={(e) => setLength(e.target.value)} 
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {dName!=='OUR_DELIVERY' && (err===true&&length==='') && <small style={{color: 'red'}}>Length is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&breadth==='') ? 'p-invalid' : ''}>Breadth</label>
              <InputText 
                style={{width: '100%'}} value={breadth} 
                onChange={(e) => setBreadth(e.target.value)} 
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {dName!=='OUR_DELIVERY' && (err===true&&breadth==='') && <small style={{color: 'red'}}>Breadth is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&height==='') ? 'p-invalid' : ''}>Height</label>
              <InputText 
                style={{width: '100%'}} value={height} 
                onChange={(e) => setHeight(e.target.value)} 
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {dName!=='OUR_DELIVERY' && (err===true&&height==='') && <small style={{color: 'red'}}>Height is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&pickUpLoc==='') ? 'p-invalid' : ''}>Pick Up Location</label>
              <InputText 
                style={{width: '100%'}} value={pickUpLoc} 
                onChange={(e) => setPLoc(e.target.value)} 
              />
              {(err===true&&pickUpLoc==='') && <small style={{color: 'red'}}>Pick Up Location is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&shipping==='') ? 'p-invalid' : ''}>Shipping Charges</label>
              <InputText 
                style={{width: '100%'}} value={shipping} 
                onChange={(e) => setShip(e.target.value)} 
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {(err===true&&shipping==='') && <small style={{color: 'red'}}>Shipping Charges is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&cod==='') ? 'p-invalid' : ''}>COD Charges</label>
              <InputText 
                style={{width: '100%'}} value={cod} 
                onChange={(e) => setCod(e.target.value)}
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {dName!=='OUR_DELIVERY' && (err===true&&cod==='') && <small style={{color: 'red'}}>COD Charges is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&discount==='') ? 'p-invalid' : ''}>Discount</label>
              <InputText 
                style={{width: '100%'}} value={discount} 
                onChange={(e) => setDiscnt(e.target.value)} 
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {dName!=='OUR_DELIVERY' && (err===true&&discount==='') && <small style={{color: 'red'}}>Discount is required.</small>}
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&OrderAmnt==='') ? 'p-invalid' : ''}>Order Amount</label>
              <InputText 
                style={{width: '100%'}} value={OrderAmnt} readOnly={true}
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
            </Col>

            <Col xs={12} sm={6} md={4}>
              <label style={{width: '100%'}} className={(err===true&&cAmnt==='') ? 'p-invalid' : ''}>Collectable Amount</label>
              <InputText 
                style={{width: '100%'}} value={cAmnt} 
                onChange={(e) => setCAmnt(e.target.value)} 
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              />
              {dName!=='OUR_DELIVERY' && (err===true&&cAmnt==='') && <small style={{color: 'red'}}>Collectable Amount is required.</small>}
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Excel Download */}
      <Dialog
        visible={edata} onHide={() => setEData(false)}
        header={<div style={{ textAlign: 'center' }}>Excel Data - Orders</div>}
        footer={<div style={{ textAlign: 'center' }}><button style={{
          backgroundColor: '#338D7e', color: '#fff', padding: '1%', 
          border: '0px solid', paddingLeft: '3%', paddingRight: '3%', 
          borderRadius: '5px'
          }} onClick={handleXData}>
            Submit
          </button>
        </div>} 
        style={{width: '35vw', margin: '2%'}}
      >
        <Container style={{ marginTop: '3%' }}>
          <Row>
            <Col xs={6}>
              <div className='field'>
                <Calendar
                  value={start} placeholder='Start Date'
                  onChange={(e) => setStart(e.value)}
                  dateFormat="dd-mm-yy" showIcon style={{ height: '35px' }}
                />
              </div>
            </Col>
            
            <Col xs={6}>
              <div className='field'>
                <Calendar
                  value={end} placeholder='End Date'
                  onChange={(e) => setEnd(e.value)} maxDate={new Date()}
                  dateFormat="dd-mm-yy" showIcon style={{ height: '35px' }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Cancel Orders */}
      <Dialog 
        header={<h5 style={{textAlign: 'center'}}>Do you want to Cancel the order ?</h5>} style={{width: '35vw', height: 'auto'}}
        visible={cOpen} onHide={() => { setCOpen(false); setSObj('') }}
        footer={<div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <div style={{color: '#000', fontWeight: '700'}} onClick={() => { setCOpen(false); setSObj(null)} }>No</div>
          <div style={{color: 'red', fontWeight: '700'}} onClick={() => cancelOrder()}>Yes</div>
        </div>}
      >
        <div style={{textAlign: 'center', fontWeight: '700'}}>{selObj && selObj.ORDER_TRACKER_ID}</div>
      </Dialog>

      {/* Update Order Status */}
      <Dialog 
        visible={chngOpn} onHide={() => setCOpn(false)}
        header={<div>Change Order Status</div>}
        style={{width: '35vw', height: 'auto'}}
      >
        {selObj && <><div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          {(selObj.STATUS!=='DISPATCHED' && selObj.STATUS!=='DELIVERED') && <Tag severity="info" value={selObj.DELIVERY_TYPE!=='STORE_PICKUP' ? 'Ready to Dispatch' : 'Ready to Pickup'} icon={(orderChn==='READY_TO_DISPATCH' || orderChn==='READY_TO_PICKUP')?'pi pi-check':''} onClick={() => setOChn(selObj.DELIVERY_TYPE!=='STORE_PICKUP'?'READY_TO_DISPATCH':'READY_TO_PICKUP')} />}
          
          {selObj.DELIVERY_TYPE!=='STORE_PICKUP' && 
            (selObj.STATUS!=='DELIVERED') && <Tag severity='warning' value="Dispatch" icon={orderChn==='DISPATCHED' ? 'pi pi-check' : ''} onClick={() => setOChn('DISPATCHED')} />
          }

          <Tag severity="success" value={selObj.DELIVERY_TYPE!=='STORE_PICKUP' ? 'Delivery' : 'Picked Up'} icon={(orderChn==='DELIVERED' || orderChn==='PICKED_UP') ? 'pi pi-check' : ''} onClick={() => setOChn(selObj.DELIVERY_TYPE!=='STORE_PICKUP'?'DELIVERED':'PICKED_UP')} />
        </div>

        <div>
          <h6 style={{marginTop: '1vh'}}>{selObj.DELIVERY_TYPE!=='STORE_PICKUP'?'Delivery Address':'Customer Mobile No.'}</h6>

          <InputTextarea
            inputId="chngadd" rows={4} cols={40} value={delAdd} 
            onChange={(e) => setDAdd(e.target.value)}
            style={{width: '100%', height: '14vh'}}
            readOnly={true}
          />
        </div>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '7px'}}>
          <div style={{
            border: '2px solid dodgerblue', 
            borderRadius: '5px', padding: '5px', 
            color: 'dodgerblue', cursor: 'pointer'
          }} onClick={() => changeOrder()}>
            Change Order Status
          </div>
        </div></>}
      </Dialog>
    </div>
  );
};
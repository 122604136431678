import React, { useState, useEffect } from 'react';

import axios from 'axios';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';

import { 
  STORES_LIST, EDIT_STORES, ADD_STORE, 
  ADD_STORE_COUNTRIES_LIST, ADD_STORE_STATES_LIST, 
  ADD_STORE_MANAGERS_LIST 
} from '../../config/config.js';

import './store.css';

import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
 
import { Form, Field } from 'react-final-form';
import { Container, Row, Col } from 'reactstrap';

let intialvalues = {
  storeName: '', storeManager: '',
  address1: '', address2: '', country: '', 
  state: '', city: '', pin: '', 
  latitude: '', longitude: '', contact: '',
};

const StoresList = () => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  // const [bulkUpload, setBUpld] = useState(false);
  const [countryDropdown, setCountryDropdown] = useState([]);
  const [stateDropdown, setStateDropdown] = useState([]);
  const [sName, setSName] = useState('');
  const [cName, setCName] = useState('');

  const [see, setSee] = useState([]);
  const [viewName, setVName] = useState('');
  const [newId, setNewId] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [load, setLoad] = useState(false);
  const [filter, setFilter] = useState('');
  const [view, setView] = useState('');

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  const handleOpen = () => {
    setOpen(true); loadDropdowns();

    intialvalues = {
      storeName: '',
      storeManager: '',
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      pin: '',
      latitude: '',
      longitude: '',
      contact: '',
    };
  };

  const loadDropdowns = () => {
    axios({
      method: 'get',
      url: ADD_STORE_MANAGERS_LIST
    }).then((res) => {
      if (res.data) {
        setSee(res.data);
      }
    }).catch((err) => {
      console.log(err);
    });

    axios({
      method: 'get',
      url: ADD_STORE_COUNTRIES_LIST
    }).then((res) => {
      setCountryDropdown(res.data);
      stateHandler(res.data[0].LOOKUP_NAME);
    }).catch((err) => {
      console.lolg(err);
    });
  };

  const handleClose = () => { 
    setOpen(false); setVName(''); 
    setNewId(''); setCName(''); setSName('');

    intialvalues = {
      storeName: '', storeManager: '',
      address1: '', address2: '', country: '', 
      state: '', city: '', pin: '', 
      latitude: '', longitude: '', contact: '',
    };
  };

  const handleView = (row) => {
    axios({
      method: 'GET',
      url: EDIT_STORES + row.ID
    }).then((res) => {
      setView(res.data); setPopup(true);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleEdit = (row) => {
    setOpen(true); setNewId(row.ID);     
    setVName(row.STORE_NAME); loadDropdowns();
    setCName(row.COUNTRY_NAME); setSName(row.STATE_NAME);

    intialvalues = {
      storeName: row.STORE_NAME,
      storeManager: row.STORE_MANAGER_ID,
      address1: row.ADDRESS_LINE1,
      address2: row.ADDRESS_LINE2,
      country: row.COUNTRY_ID,
      state: row.STATE_ID,
      city: row.CITY,
      pin: row.PINCODE,
      latitude: row.LATITUDE,
      longitude: row.LONGITUDE,
      contact: row.CONTACT_NUMBER,
    };
  };

  const stateHandler = (CName) => {
    axios({
      method: 'get',
      url: ADD_STORE_STATES_LIST + '&LOOKUP_TYPE_NAME=' + CName
    }).then((res) => {
      if (res.data) {
        setStateDropdown(res.data);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleClickOpen = (event) => {
    setNewId(event.ID); setOpenPopup(true);
  };

  const handleCloseDelete = () => {
    setOpenPopup(false); setNewId('');
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: EDIT_STORES + newId,
      'Content-Type': 'multipart/form-data',
      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      setOpenPopup(false); setNewId('');
      loadData();
    }).catch((err) => {
      console.log(err);
    });
  };

  const loadData = (page , filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: STORES_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
    }).then((res) => {
      if (res.data) {
        setTableData(res.data.rows); setLoad(false);
        setTRecords(res.data.total_count);
      } else {
        setLoad(false); setTRecords(0);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    loadData(page, filter, rows);
  }, []);

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setPage(e.page); 
    setRows(e.rows); loadData(e.page, filter, e.rows);
  };

  // const handleChange = (value) => {
  //   const data = countryDropdown.filter((option) => (option.ID === value));
  //   stateHandler(data[0].LOOKUP_NAME); console.log(data[0]);
  // };

  const handleFilter = (e) => {
    if(e.target.value){
      setFilter(e.target.value);
    }else{
      setFilter(''); loadData(page, filter, rows);
    }
  };

  const tableHeader=()=>{
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Stores List</h3>
      </div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e) => handleFilter(e)} placeholder='Search...' />
        <Button className='p-button-outlined p-button-raised table-button m-2' onClick={handleOpen} icon={<img src={Add} style={{ width: '25px', height: '20px', color: '#fff' }} />} label='Add' />
      </div>
    </div>
  };

  const actionHeader=(rowData)=>{
    return <div style={{display: 'flex', flexDirection: 'row'}}>
      <img 
        src={View} alt='View Image'
        onClick={() => handleView(rowData)}
        style={{marginRight: '7px', width: '25px', height: '20px'}}
      />

      <img
        src={Edit} alt='Edit Image'
        onClick={() => handleEdit(rowData)}
        style={{marginRight: '7px', width: '25px', height: '20px'}}
      />

      <img
        src={Delete} alt='Delete Image'
        style={{width: '25px', height: '20px'}}
        onClick={() => handleClickOpen(rowData)}
      />
    </div> 
  };

  const addStore = (obj, form) => {
    axios({
      method: 'post',
      url: ADD_STORE,
      data: obj,
      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      handleClose(); form.restart();
      loadData(page, filter, rows); 
    });
  };

  const editStore = (obj, form) => {
    axios({
      method: 'put',
      url: EDIT_STORES + newId,
      data: obj,
      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      handleClose(); form.restart();
      loadData(page, filter, rows);
    });
  };

  const handleOnSubmit = (data, form) => {
    let obj = { 
      STORE_NAME: data.storeName,
      STORE_MANAGER_ID: data.storeManager,
      ADDRESS_LINE1: data.address1,
      ADDRESS_LINE2: data.address2,
      CITY: data.city,
      PINCODE: data.pin,
      COUNTRY_ID: data.country,
      COUNTRY_NAME: cName,
      STATE_ID: data.state,
      STATE_NAME: sName,
      UPDATED_BY: JSON.parse(sessionStorage.getItem('id')),
      LATITUDE: data.latitude,
      LONGITUDE: data.longitude,
      CONTACT_NUMBER: data.contact, 
    };

    if(newId === ''){
      obj.CREATED_BY = JSON.parse(sessionStorage.getItem('id'));
      addStore(obj, form);
    }else{
      editStore(obj, form);
    };
  };

  const validate = (data) => {
    let errors = {};

    if (!data.storeName) {
      errors.storeName = 'Store Name is required.';
    }

    if (!data.storeManager) {
      errors.storeManager = 'Store Manager is required.';
    }

    if (!data.address1) {
      errors.address1 = 'Address Line 1 is required.';
    }

    if (!data.address2) {
      errors.address2 = 'Address Line 2 is required.';
    }

    if (!data.country) {
      errors.country = 'Country is required.';
    }

    if (!data.state) {
      errors.state = 'State is required.';
    }

    if (!data.city) {
      errors.city = 'City is required.';
    }

    if (!data.pin) {
      errors.pin = 'PIN Code is required.';
    }

    if (!data.latitude) {
      errors.latitude = 'Latitude is required.';
    }

    if (!data.longitude) {
      errors.longitude = 'Longitude is required.';
    }

    if (!data.contact) {
      errors.contact = 'Contact No. is required.';
    }

    // if (!data.email) {
    //   errors.email = 'Email is required.';
    // }
    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
    //   errors.email = 'Invalid email address. E.g. example@email.com';
    // }

    return errors;
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const handleState = (val) => {
    let arr = stateDropdown.filter((it) => it.ID === val);
    setSName(arr[0].LOOKUP_NAME);
  };

  const handleCountry = (val) => {
    let arr = countryDropdown.filter((it) => it.ID === val);
    setCName(arr[0].LOOKUP_NAME);
  };

  return (
    <div className='pt-4 m-3'>
      {/* Table */}
      <div className='datatable'>
        <DataTable 
          value={tableData} removableSort loading={load}
          globalFilter={filter} header={tableHeader}
        >
          <Column header='Store Name' field='STORE_NAME' sortable />
          <Column header='Store Manager' field='STORE_MANAGER_NAME' sortable />
          <Column header='Address' field='ADDRESS_LINE1' sortable />
          <Column header='Contact Number' field='CONTACT_NUMBER' sortable />
          <Column header='Actions' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} template={pageTemplate} onPageChange={onPage} />
      </div>

      {/* delete store pop-up */}
      <Dialog
        visible={openPopup}
        onHide={handleCloseDelete}
        style={{width: '35vw', height: '25vh'}}
        header={<div className='text-center'>You want to delete the record ?</div>}
      >
        <div style={{textAlign: 'center'}}>
          <Button onClick={handleCloseDelete} className='mx-2'>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </div>
      </Dialog>

      {/* Add / Edit store pop-up  */}
      <Dialog
        visible={open} onHide={handleClose}
        style={{width: '55vw', height: '82vh'}}
        header={<div className='text-center'>
          {newId==='' ? 'Add Store' : `Edit Store - ${viewName}`}
        </div>}
      >
        <Container style={{marginTop: '0.7%'}}>
          <Form 
            onSubmit={handleOnSubmit} 
            initialValues={intialvalues}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} sm={6}>
                    <Field name='storeName' render={({ input, meta }) => (
                      <>
                        <label htmlFor="storeName" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Store Name*</label>
                        <InputText id="storeName" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
                
                  <Col xs={12} sm={6}>
                    <Field name='storeManager' render={({ input, meta }) => (
                      <>
                        <label htmlFor="storeManager" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Store Manager*</label>
                        <Dropdown 
                          id="storeManager" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                          style={{width: '100%'}} options={see} optionLabel='NAME' optionValue='ID'
                        />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
                
                  <Col xs={12} sm={6}>
                    <Field name='country' render={({ input, meta }) => (
                      <>
                        <label htmlFor="country" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Country*</label>
                        <Dropdown 
                          id="country" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                          style={{width: '100%'}} options={countryDropdown} optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID'
                          onChange={(e) => { input.onChange(e.value); handleCountry(e.value) }}
                        />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
                
                  <Col xs={12} sm={6}>
                    <Field name='state' render={({ input, meta }) => (
                      <>
                        <label htmlFor="state" className={classNames({ 'p-error': isFormFieldValid(meta) })}>State*</label>
                        <Dropdown 
                          id="state" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                          style={{width: '100%'}} options={stateDropdown} optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID'  
                          onChange={(e) => { input.onChange(e.value); handleState(e.value) }}
                        />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
              
                  <Col xs={12} sm={6}>
                    <Field name='city' render={({ input, meta }) => (
                      <>
                        <label htmlFor="city" className={classNames({ 'p-error': isFormFieldValid(meta) })}>City*</label>
                        <InputText id="city" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
                
                  <Col xs={12} sm={6}>
                    <Field name='pin' render={({ input, meta }) => (
                      <>
                        <label htmlFor="pin" className={classNames({ 'p-error': isFormFieldValid(meta) })}>PIN Code*</label>
                        <InputMask id="pin" {...input} mask='999999' className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} mode="decimal" useGrouping={false} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
                
                  <Col xs={12} sm={6}>
                    <Field name='address1' render={({ input, meta }) => (
                      <>
                        <label htmlFor="address1" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Address Line 1*</label>
                        <InputText id="address1" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
                
                  <Col xs={12} sm={6}>
                    <Field name='address2' render={({ input, meta }) => (
                      <>
                        <label htmlFor="address2" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Address Line 2*</label>
                        <InputText id="address2" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>
                
                  <Col xs={12} sm={6}>
                    <Field name='latitude' render={({ input, meta }) => (
                      <>
                        <label htmlFor="latitude" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Latitude*</label>
                        <InputText id="latitude" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>

                  <Col xs={12} sm={6}>
                    <Field name='longitude' render={({ input, meta }) => (
                      <>
                        <label htmlFor="longitude" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Longitude*</label>
                        <InputText id="longitude" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>

                  <Col xs={12} sm={6}>
                    <Field name='contact' render={({ input, meta }) => (
                      <>
                        <label htmlFor="contact" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Contact No.*</label>
                        <InputMask id="contact" mask='9999999999' {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} style={{width: '100%'}} mode="decimal" useGrouping={false} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} /> 
                  </Col>

                  <Col xs={12} sm={12} className='text-center' style={{width: '100%', marginTop: '1%'}}>
                    <Button label='Submit' type='submit' className='table-button' />
                  </Col>
                </Row>
              </form>
            )} 
          />
        </Container>
      </Dialog>

      {/* view store pop-up */}
      <Dialog
        visible={popup} onHide={() => { setPopup(false) }} maximizable
        header={<div style={{textAlign:'center'}}>View Store - {view.STORE_NAME}</div>}
        style={{width:'65vw', height:'50vh'}} 
      >
        <Container>
          <Row>
            <Col xs={4} sm={3}>
              Store Name 
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.STORE_NAME}
            </Col>

            <Col xs={4} sm={3}>
              Store Manager 
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.STORE_MANAGER_NAME}
            </Col>

            <Col xs={4} sm={3}>
              Address
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.ADDRESS_LINE1 + ''+ view.ADDRESS_LINE2}
            </Col>

            <Col xs={4} sm={3}>
              Country
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.COUNTRY_NAME}
            </Col>

            <Col xs={4} sm={3}>
              State
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.STATE_NAME}
            </Col>

            <Col xs={4} sm={3}>
              City
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.CITY}
            </Col>

            <Col xs={4} sm={3}>
              PIN Code
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.PINCODE}
            </Col>

            <Col xs={4} sm={3}>
              Contact Number
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.CONTACT_NUMBER}
            </Col>

            <Col xs={4} sm={3}>
              Latitude & Longitude
            </Col>
            <Col xs={2} sm={1}> 
              :
            </Col>
            <Col xs={6} sm={8}> 
              {view.LATITUDE + ', '+ view.LONGITUDE}
            </Col>
          </Row>          
        </Container>
      </Dialog>
    </div>
  );
};

export default StoresList;
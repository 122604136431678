import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';

import { GET_LOGS } from '../../config/config';
import { Dialog } from 'primereact/dialog';

const Loggers = () => {
    const [logData, setLData] = useState('');
    const [date, setDate] = useState(null);
    const [err, setErr] = useState(false);

    const handleDate = (dt) => {
        if(dt!==null){
            let d = new Date(dt);

            return d.getFullYear() + "-" + ((d.getMonth()+1)<10 ? '0'+(d.getMonth()+1) : (d.getMonth()+1)) + "-" + (d.getDate()>=10 ? d.getDate() : '0'+d.getDate());
        }else{
            let d = new Date(); setDate(d);

            return d.getFullYear() + "-" + ((d.getMonth()+1)<10 ? '0'+(d.getMonth()+1) : (d.getMonth()+1)) + "-" + (d.getDate()>=10 ? d.getDate() : '0'+d.getDate());
        }
    };

    const submitDate = (dt) => {
        let d = new Date(dt);

        return d.getFullYear() + "-" + ((d.getMonth()+1)<10 ? '0'+(d.getMonth()+1) : (d.getMonth()+1)) + "-" + (d.getDate()>=10 ? d.getDate() : '0'+d.getDate());
    };

    const getLogs = () => {
        axios({
            method: 'PATCH',
            url: GET_LOGS,
            data: {'DATE': handleDate(date)},
        }).then((res) => {
            if(!res.data.data.message){
                setLData(res.data.data); setErr(false);
            }else{
                setLData(res.data.data.message); setErr(false)
            }
        }).catch((err) => {
            if(err.response.status){
                setErr(true);
            }
        });
    };

    const submitLogs = () => {
        if(date!==null){
            axios({
                method: 'PATCH',
                url: GET_LOGS,
                data: {'DATE': submitDate(date)},
            }).then((res) => {
                if(!res.data.data.message){
                    setLData(res.data.data); setErr(false);
                }else{
                    setLData(res.data.data.message); setErr(false)
                }
            }).catch((err) => {
                if(err.response.status){
                    setErr(true);
                }
            });
        }else{
            setErr(true);
        }
    };
    
    useEffect(() => {
        getLogs();
    }, []);

    return (
        <div className='pt-4 m-3'>
            <div style={{ width: "100%" }} className='datatable'>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{textAlign: 'left', width: '50%'}}>
                        <h4>Logs</h4>
                    </div>

                    <div style={{display: 'flex', width: '50%'}}>
                        <div style={{width: '45%'}}>
                            <Calendar placeholder='Enter date' value={date} onChange={(e) => setDate(e.value)} dateFormat="dd-mm-yy" />
                        </div>

                        <div style={{width: '55%'}}>
                            <Button label='submit' style={{paddingTop: 3, paddingBottom: 3}} onClick={() => submitLogs()} />
                        </div>
                    </div>
                </div>

                <div style={{marginTop: '1.5vh'}}>
                    <InputTextarea 
                        rows={22} cols={80} value={logData} 
                        style={{width: '100%'}} 
                    />   
                </div>
            </div>

            <Dialog
                visible={err} header={<div>Warning !</div>} style={{width: '250px'}}
                onHide={() => {setErr(false); window.location.replace('/home')}}
                
            >
                <div style={{textAlign: 'center', color: 'red', fontSize: 20}}>
                    Contact Admin.
                </div>
            </Dialog>
        </div>
    );
};

export default Loggers;
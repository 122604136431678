import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { ROLES_LIST, ADD_USER_ROLES, USER_ROLES_DROPDOWN, EDIT_USER_ROLES } from '../../config/config.js';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';

import { Button, Container, Row, Col } from 'reactstrap';

import { ScrollPanel } from 'primereact/scrollpanel';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

import CheckboxTree from 'react-checkbox-tree';

const RolesList = () => {
  const [tableData, setTableData] = useState([]);
  const [rolesDropDown, setRolesDropDown] = useState([]);
  const [addOpen, setAOpn] = useState(false);
  const [editOpen, setEOpn] = useState(false);
  const [view, setView] = useState(false);
  const [del, setDel] = useState(false);
  const [des, setDes] = useState('');
  const [roleId, setRoleId] = useState('');
  const [rName, setRName] = useState('');
  const [err, setErr] = useState(false);
  const [load, setLoad] = useState(false);
  const [editRole, setERole] = useState('');
  const [editID, setEId] = useState('');
  const [filter, setFilter] = useState('');

  const [obj, setObj] = useState({});
  const [list, setList] = useState([]);
  const [opts, setOpts] = useState([]);
  const [selObj, setSObj] = useState([]);

  const loadData = () => {
    setLoad(true);

    axios({
      method: 'get',
      url: ROLES_LIST
    }).then((res) => {
      setTableData(res.data.rows); setLoad(false);

      getRoleJSON(res.data.rows[0].ROLE_JSON.menu);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getAddRoles = () => {
    // add roles list
    axios({
      method: 'get',
      url: USER_ROLES_DROPDOWN
    }).then((res) => {
      if (res.data) {
        setRolesDropDown(res.data);
      }
    }).catch((err) => {
      console.log(err)
    });
  };
    
  const getRoleJSON = (val) => {
    let objs = Object.keys(val), arr = [], sobj = {};

    for(let a=0; a<objs.length; a++){
      sobj[objs[a]] = {actions: {create: false, edit: false, delete: false, status: false}, enabled: false}
    };

    for(let i=0; i<objs.length; i++){
      arr.push({
        value: objs[i],
        label: objs[i].charAt(0).toUpperCase() + objs[i].slice(1),
        children: [
          {value: `${objs[i]}-create`, label: 'Create'},
          {value: `${objs[i]}-edit`, label: 'Edit'},
          {value: `${objs[i]}-delete`, label: 'Delete'},
          {value: `${objs[i]}-status`, label: 'View'},
        ],
      });
    };

    setObj(sobj); setList(arr);
  };

  useEffect(() => {
    loadData(); getAddRoles();
  }, []);

  const addRoles = () => {
    setAOpn(true); setERole('');
  };

  const handleChange = (event) => {
    let data = rolesDropDown.filter((option) => (option.ID === event.target.value));
    setRoleId(event.target.value); setRName(data[0].LOOKUP_NAME);
  };

  const tableHeader = () => {
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Roles List</h3></div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder='Search...' />
        <Button variant="contained" className='table-button m-2' onClick={addRoles}>
          <img src={Add} style={{ width: '25px', height: '20px', marginRight: 5 }}/>Add
        </Button>
      </div>
    </div>
  };

  const addPage = (row) => {
    let objss = obj;

    for(let i=0; i<row.length; i++){
      objss[row[i].split('-')[0]] = {'enabled': true, actions: {create: false, edit: false, delete: false, status: false}};
    };

    for(let j=0; j<row.length; j++){
      switch(row[j]){
        case 'adminusers-create':
          objss['adminusers']['actions'] = { ...objss['adminusers']['actions'], 'create' : true };
          break;
        case 'adminusers-edit':
          objss['adminusers']['actions'] = { ...objss['adminusers']['actions'], 'edit' : true };
          break;
        case 'adminusers-delete':
          objss['adminusers']['actions'] = { ...objss['adminusers']['actions'], 'delete' : true };
          break;
        case 'adminusers-status':
          objss['adminusers']['actions'] = { ...objss['adminusers']['actions'], 'status' : true };
          break;
        case 'banners-create':
          objss['banners']['actions'] = { ...objss['banners']['actions'], 'create' : true };
          break;
        case 'banners-edit':
          objss['banners']['actions'] = { ...objss['banners']['actions'], 'edit' : true };
          break;
        case 'banners-delete':
          objss['banners']['actions'] = { ...objss['banners']['actions'], 'delete' : true };
          break;
        case 'banners-status':
          objss['banners']['actions'] = { ...objss['banners']['actions'], 'status' : true };
          break;
        case 'bikereferrals-create':
          objss['bikereferrals']['actions'] = { ...objss['bikereferrals']['actions'], 'create' : true };
          break;
        case 'bikereferrals-edit':
          objss['bikereferrals']['actions'] = { ...objss['bikereferrals']['actions'], 'edit' : true };
          break;
        case 'bikereferrals-delete':
          objss['bikereferrals']['actions'] = { ...objss['bikereferrals']['actions'], 'delete' : true };
          break;
        case 'bikereferrals-status':
          objss['bikereferrals']['actions'] = { ...objss['bikereferrals']['actions'], 'status' : true };
          break;
        case 'blogs-create':
          objss['blogs']['actions'] = { ...objss['blogs']['actions'], 'create' : true };
          break;
        case 'blogs-edit':
          objss['blogs']['actions'] = { ...objss['blogs']['actions'], 'edit' : true };
          break;
        case 'blogs-delete':
          objss['blogs']['actions'] = { ...objss['blogs']['actions'], 'delete' : true };
          break;
        case 'blogs-status':
          objss['blogs']['actions'] = { ...objss['blogs']['actions'], 'status' : true };
          break;
        case 'categories-create':
          objss['categories']['actions'] = { ...objss['categories']['actions'], 'create' : true };
          break;
        case 'categories-edit':
          objss['categories']['actions'] = { ...objss['categories']['actions'], 'edit' : true };
          break;
        case 'categories-delete':
          objss['categories']['actions'] = { ...objss['categories']['actions'], 'delete' : true };
          break;
        case 'categories-status':
          objss['categories']['actions'] = { ...objss['categories']['actions'], 'status' : true };
          break;
        case 'changepassword-create':
          objss['changepassword']['actions'] = { ...objss['changepassword']['actions'], 'create' : true };
          break;
        case 'changepassword-edit':
          objss['changepassword']['actions'] = { ...objss['changepassword']['actions'], 'edit' : true };
          break;
        case 'changepassword-delete':
          objss['changepassword']['actions'] = { ...objss['changepassword']['actions'], 'delete' : true };
          break;
        case 'changepassword-status':
          objss['changepassword']['actions'] = { ...objss['changepassword']['actions'], 'status' : true };
          break;
        case 'coupons-create':
          objss['coupons']['actions'] = { ...objss['coupons']['actions'], 'create' : true };
          break;
        case 'coupons-edit':
          objss['coupons']['actions'] = { ...objss['coupons']['actions'], 'edit' : true };
          break;
        case 'coupons-delete':
          objss['coupons']['actions'] = { ...objss['coupons']['actions'], 'delete' : true };
          break;
        case 'coupons-status':
          objss['coupons']['actions'] = { ...objss['coupons']['actions'], 'status' : true };
          break;
        case 'endusers-create':
          objss['endusers']['actions'] = { ...objss['endusers']['actions'], 'create' : true };
          break;
        case 'endusers-edit':
          objss['endusers']['actions'] = { ...objss['endusers']['actions'], 'edit' : true };
          break;
        case 'endusers-delete':
          objss['endusers']['actions'] = { ...objss['endusers']['actions'], 'delete' : true };
          break;
        case 'endusers-status':
          objss['endusers']['actions'] = { ...objss['endusers']['actions'], 'status' : true };
          break;
        case 'faqs-create':
          objss['faqs']['actions'] = { ...objss['faqs']['actions'], 'create' : true };
          break;
        case 'faqs-edit':
          objss['faqs']['actions'] = { ...objss['faqs']['actions'], 'edit' : true };
          break;
        case 'faqs-delete':
          objss['faqs']['actions'] = { ...objss['faqs']['actions'], 'delete' : true };
          break;
        case 'faqs-status':
          objss['faqs']['actions'] = { ...objss['faqs']['actions'], 'status' : true };
          break;
        case 'home-create':
          objss['home']['actions'] = { ...objss['home']['actions'], 'create' : true };
          break;
        case 'home-edit':
          objss['home']['actions'] = { ...objss['home']['actions'], 'edit' : true };
          break;
        case 'home-delete':
          objss['home']['actions'] = { ...objss['home']['actions'], 'delete' : true };
          break;
        case 'home-status':
          objss['home']['actions'] = { ...objss['home']['actions'], 'status' : true };
          break;
        case 'logs-create':
          objss['logs']['actions'] = { ...objss['logs']['actions'], 'create' : true };
          break;
        case 'logs-edit':
          objss['logs']['actions'] = { ...objss['logs']['actions'], 'edit' : true };
          break;
        case 'logs-delete':
          objss['logs']['actions'] = { ...objss['logs']['actions'], 'delete' : true };
          break;
        case 'logs-status':
          objss['logs']['actions'] = { ...objss['logs']['actions'], 'status' : true };
          break;
        case 'lookupcodes-create':
          objss['lookupcodes']['actions'] = { ...objss['lookupcodes']['actions'], 'create' : true };
          break;
        case 'lookupcodes-edit':
          objss['lookupcodes']['actions'] = { ...objss['lookupcodes']['actions'], 'edit' : true };
          break;
        case 'lookupcodes-delete':
          objss['lookupcodes']['actions'] = { ...objss['lookupcodes']['actions'], 'delete' : true };
          break;
        case 'lookupcodes-status':
          objss['lookupcodes']['actions'] = { ...objss['lookupcodes']['actions'], 'status' : true };
          break;
        case 'lookuptypes-create':
          objss['lookuptypes']['actions'] = { ...objss['lookuptypes']['actions'], 'create' : true };
          break;
        case 'lookuptypes-edit':
          objss['lookuptypes']['actions'] = { ...objss['lookuptypes']['actions'], 'edit' : true };
          break;
        case 'lookuptypes-delete':
          objss['lookuptypes']['actions'] = { ...objss['lookuptypes']['actions'], 'delete' : true };
          break;
        case 'lookuptypes-status':
          objss['lookuptypes']['actions'] = { ...objss['lookuptypes']['actions'], 'status' : true };
          break;
        case 'notifications-create':
          objss['notifications']['actions'] = { ...objss['notifications']['actions'], 'create' : true };
          break;
        case 'notifications-edit':
          objss['notifications']['actions'] = { ...objss['notifications']['actions'], 'edit' : true };
          break;
        case 'notifications-delete':
          objss['notifications']['actions'] = { ...objss['notifications']['actions'], 'delete' : true };
          break;
        case 'notifications-status':
          objss['notifications']['actions'] = { ...objss['notifications']['actions'], 'status' : true };
          break;
        case 'orderreports-create':
          objss['orderreports']['actions'] = { ...objss['orderreports']['actions'], 'create' : true };
          break;
        case 'orderreports-edit':
          objss['orderreports']['actions'] = { ...objss['orderreports']['actions'], 'edit' : true };
          break;
        case 'orderreports-delete':
          objss['orderreports']['actions'] = { ...objss['orderreports']['actions'], 'delete' : true };
          break;
        case 'orderreports-status':
          objss['orderreports']['actions'] = { ...objss['orderreports']['actions'], 'status' : true };
          break;
        case 'orders-create':
          objss['orders']['actions'] = { ...objss['orders']['actions'], 'create' : true };
          break;
        case 'orders-edit':
          objss['orders']['actions'] = { ...objss['orders']['actions'], 'edit' : true };
          break;
        case 'orders-delete':
          objss['orders']['actions'] = { ...objss['orders']['actions'], 'delete' : true };
          break;
        case 'orders-status':
          objss['orders']['actions'] = { ...objss['orders']['actions'], 'status' : true };
          break;
        case 'products-create':
          objss['products']['actions'] = { ...objss['products']['actions'], 'create' : true };
          break;
        case 'products-edit':
          objss['products']['actions'] = { ...objss['products']['actions'], 'edit' : true };
          break;
        case 'products-delete':
          objss['products']['actions'] = { ...objss['products']['actions'], 'delete' : true };
          break;
        case 'products-status':
          objss['products']['actions'] = { ...objss['products']['actions'], 'status' : true };
          break;
        case 'profile-create':
          objss['profile']['actions'] = { ...objss['profile']['actions'], 'create' : true };
          break;
        case 'profile-edit':
          objss['profile']['actions'] = { ...objss['profile']['actions'], 'edit' : true };
          break;
        case 'profile-delete':
          objss['profile']['actions'] = { ...objss['profile']['actions'], 'delete' : true };
          break;
        case 'profile-status':
          objss['profile']['actions'] = { ...objss['profile']['actions'], 'status' : true };
          break;
        case 'settings-create':
          objss['settings']['actions'] = { ...objss['settings']['actions'], 'create' : true };
          break;
        case 'settings-edit':
          objss['settings']['actions'] = { ...objss['settings']['actions'], 'edit' : true };
          break;
        case 'settings-delete':
          objss['settings']['actions'] = { ...objss['settings']['actions'], 'delete' : true };
          break;
        case 'settings-status':
          objss['settings']['actions'] = { ...objss['settings']['actions'], 'status' : true };
          break;  
        case 'storemanagers-create':
          objss['storemanagers']['actions'] = { ...objss['storemanagers']['actions'], 'create' : true };
          break;
        case 'storemanagers-edit':
          objss['storemanagers']['actions'] = { ...objss['storemanagers']['actions'], 'edit' : true };
          break;
        case 'storemanagers-delete':
          objss['storemanagers']['actions'] = { ...objss['storemanagers']['actions'], 'delete' : true };
          break;
        case 'storemanagers-status':
          objss['storemanagers']['actions'] = { ...objss['storemanagers']['actions'], 'status' : true };
          break;
        case 'stores-create':
          objss['stores']['actions'] = { ...objss['stores']['actions'], 'create' : true };
          break;
        case 'stores-edit':
          objss['stores']['actions'] = { ...objss['stores']['actions'], 'edit' : true };
          break;
        case 'stores-delete':
          objss['stores']['actions'] = { ...objss['stores']['actions'], 'delete' : true };
          break;
        case 'stores-status':
          objss['stores']['actions'] = { ...objss['stores']['actions'], 'status' : true };
          break;
        case 'userroles-create':
          objss['userroles']['actions'] = { ...objss['userroles']['actions'], 'create' : true };
          break;
        case 'userroles-edit':
          objss['userroles']['actions'] = { ...objss['userroles']['actions'], 'edit' : true };
          break;
        case 'userroles-delete':
          objss['userroles']['actions'] = { ...objss['userroles']['actions'], 'delete' : true };
          break;
        case 'userroles-status':
          objss['userroles']['actions'] = { ...objss['userroles']['actions'], 'status' : true };
          break;
      };
    };

    return objss;
  };

  const editPage = (row) => {
    let arr = [], arr1 = [], arr2 = [];

    arr1 = Object.keys(row); arr2 = Object.values(row);
    
    for(let i=0; i<arr1.length; i++){
      if(arr2[i].actions.edit === true){
        arr.push(`${arr1[i]}-edit`);
      };

      if(arr2[i].actions.create === true){
        arr.push(`${arr1[i]}-create`);
      };

      if(arr2[i].actions.delete === true){
        arr.push(`${arr1[i]}-delete`);
      };

      if(arr2[i].actions.status === true){
        arr.push(`${arr1[i]}-status`);
      };
    };

    setOpts(arr);
  };

  const handleSubmit = () => {
    let data = {
      DESCRIPTION: des, 
      USER_ROLE_ID: roleId, 
      USER_ROLE: rName, 
      ROLE_JSON: {menu : addPage(opts)},
      CREATED_BY: JSON.parse(sessionStorage.getItem('id')),
      UPDATED_BY: JSON.parse(sessionStorage.getItem('id')),
    };

    if(rName!=='' && des!==''){
      axios({
        method: 'post',
        url: ADD_USER_ROLES,
        data:  data,
        headers: {token: sessionStorage.getItem('token')}
      }).then((res) => {
        setDes(''); setRoleId(''); setErr(false);
        setRName(''); setAOpn(false); loadData();
      }).catch((err) => {
        console.log(err);
      });
    }else{
      setErr(true);
    };
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    
    let data = {
      DESCRIPTION: des, 
      USER_ROLE_ID: roleId, 
      USER_ROLE: rName, 
      ROLE_JSON: {menu : addPage(opts)},
      UPDATED_BY: JSON.parse(sessionStorage.getItem('id')),
    };

    if(rName!=='' && des!==''){
      axios({
        method: 'put',
        url: EDIT_USER_ROLES + editID,
        data: data,
        headers: {token: sessionStorage.getItem('token')}
      }).then((res) => {
        setEOpn(false); setERole(''); setErr(false);
        setRName(''); setDes(''); setRoleId(''); loadData();
      }).catch((err) => {
        console.log(err);
      });
    }else{
      setErr(true);
    }
  };

  const handleView = (row) => {
    setView(true); setERole(row); editPage(row.ROLE_JSON.menu);
  };

  const handleEdit = (row) => {
    setEOpn(true); setERole(row.USER_ROLE); setDes(row.DESCRIPTION); 
    setRoleId(row.USER_ROLE_ID); setEId(row.ID); editPage(row.ROLE_JSON.menu); 

    let data = rolesDropDown.filter((option) => (option.ID === row.USER_ROLE_ID));
    setRName(data[0].LOOKUP_NAME);
  };

  const actionHeader = (row) => {
    return <div style={{display: 'flex', flexDirection:'row'}}>
      <img
        src={View} alt='View Image' 
        style={{ marginRight: '1vw', width: '25px', height: '20px' }}
        onClick={() => handleView(row)}
      />
      
      <img
        src={Edit} alt='Edit Image'
        onClick={() => handleEdit(row)}
        style={{ marginRight: '1vw', width: '25px', height: '20px' }}
      />
      
      <img
        src={Delete} alt='Delete Image'
        style={{width: '25px', height: '20px'}}
        onClick={() => {setDel(true); setERole(row.USER_ROLE_ID)}}
      />
    </div> 
  };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} removableSort scrollable scrollHeight="75vh"
          size='medium' globalFilter={filter} header={tableHeader}
          responsiveLayout="scroll" loading={load}
        >
          <Column header='Role Name' className=' text-truncate' field='USER_ROLE' sortable />
          <Column header='Description' className=' text-truncate' field='DESCRIPTION' sortable />
          <Column header='Action' body={actionHeader} />
        </DataTable>
      </div>

      {/* Add Pop-up */}
      <Dialog 
        visible={addOpen} style={{width: '80vw', height: 'auto'}}  
        onHide={() => {setAOpn(false); setDes(''); setRoleId(''); setRName(''); setErr(false)}} 
        header={<div style={{textAlign: 'center'}}>Add Role</div>}
        footer={<div style={{textAlign: 'center'}}>
          <Button 
            onClick={handleSubmit} 
            style={{
              width: '18%', border: '0px solid',
              backgroundColor: '#00cd5c'
            }}
          >Submit</Button>
        </div>}
      >
        <Container style={{marginTop: '0.5%'}}>
          <Row>
            <Col xs={6}>
              <label style={(err===true&&roleId==='')?{color: 'red'}:{}}>User Role *</label>
              <Dropdown 
                style={{ width: '100%' }} value={roleId} 
                onChange={handleChange} options={rolesDropDown} 
                optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' 
              /> 
              {(err===true&&roleId==='') && <small style={{color: 'red'}}>User Role is required.</small>}
            </Col>

            <Col xs={6}>
              <label style={(err===true&&des==='')?{color: 'red'}:{}}>Description *</label>
              <InputText
                style={{ width: '100%' }} value={des} 
                onChange={e => setDes(e.target.value)}
              /> 
              {(err===true&&des==='') && <small style={{color: 'red'}}>Description is required.</small>}
            </Col>


            <Col xs={12} sm={12}>
              <div style={{textAlign: 'center', textDecorationLine: 'underline'}}>Page Assignment</div>

              <ScrollPanel style={{width: '100%', height: '45vh'}}>
                <CheckboxTree
                  nodes={list} checked={opts}
                  expanded={selObj} showNodeIcon={false}
                  icons={{
                    check: <i className='pi pi-check-square' />,
                    uncheck: <i className="pi pi-stop" />,
                    halfCheck: <i className='pi pi-minus-circle' />,
                    expandClose: <i className='pi pi-chevron-right' />,
                    expandOpen: <i className='pi pi-chevron-down' />,
                    expandAll: <i className='pi pi-chevron-down' />,
                    collapseAll: <i className='pi pi-chevron-right' />,
                    parentClose: <i className='pi pi-folder' />,
                    parentOpen: <i className='pi pi-folder-open' />,
                    leaf: <i className='pi pi-file' />
                  }}
                  onCheck={(checked) => setOpts(checked)}
                  onExpand={(expanded) => setSObj(expanded)}
                />
              </ScrollPanel>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Edit Pop-up */}
      <Dialog 
        visible={editOpen} style={{width: '75vw'}} 
        onHide={() => {setEOpn(false); setDes(''); setRoleId(''); setERole(''); setRName(''); setErr(false)}}
        header={<div style={{textAlign: 'center'}}>Edit Role - {editRole}</div>}
        footer={<div style={{textAlign: 'center', marginTop: '2%'}}>
          <Button
            onClick={handleEditSubmit} 
            style={{
              width: '18%', border: '0px solid',
              backgroundColor: '#00cd5c'
            }}
          >Submit</Button>
        </div>}
      >
        <Container style={{marginTop: '1%'}}>
          <Row>
            <Col xs={6}>
              <label style={(err===true&&roleId==='')?{color: 'red'}:{}}>User Role *</label>
              <Dropdown 
                style={{ width: '100%' }} value={roleId} 
                onChange={handleChange} options={rolesDropDown} 
                optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' 
              /> 
              {(err===true&&roleId==='') && <small style={{color: 'red'}}>User Role is required.</small>}
            </Col>

            <Col xs={6}>
              <label style={(err===true&&des==='')?{color: 'red'}:{}}>Description *</label>
              <InputText
                style={{ width: '100%' }} value={des} 
                onChange={e => setDes(e.target.value)}
              /> 
              {(err===true&&des==='') && <small style={{color: 'red'}}>Description is required.</small>}
            </Col>

            <Col xs={12}>
              <div style={{textAlign: 'center', textDecorationLine: 'underline'}}>Page Assignment</div>

              <ScrollPanel style={{width: '100%', height: '45vh'}}>
                <CheckboxTree
                  nodes={list} checked={opts}
                  expanded={selObj} showNodeIcon={false}
                  icons={{
                    check: <i className='pi pi-check-square' />,
                    uncheck: <i className="pi pi-stop" />,
                    halfCheck: <i className='pi pi-minus-circle' />,
                    expandClose: <i className='pi pi-chevron-right' />,
                    expandOpen: <i className='pi pi-chevron-down' />,
                    expandAll: <i className='pi pi-chevron-down' />,
                    collapseAll: <i className='pi pi-chevron-right' />,
                    parentClose: <i className='pi pi-folder' />,
                    parentOpen: <i className='pi pi-folder-open' />,
                    leaf: <i className='pi pi-file' />,
                  }}
                  onCheck={(checked) => setOpts(checked)}
                  onExpand={(expanded) => setSObj(expanded)}
                />
              </ScrollPanel>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* View Pop-up */}
      <Dialog 
        visible={view} onHide={() => setView(false)} 
        style={{height: 'auto', width: '55vw'}}
        header={<h3>View Role Details - {editRole.USER_ROLE}</h3>} 
      >
        <Container>
          <Row>
            <Col xs={3}>
              <div className='sub_heading_1'>User Type</div>
            </Col>

            <Col xs={1}>
              <b>:</b>
            </Col>

            <Col xs={8}>
              {editRole.USER_ROLE}
            </Col>

            <Col xs={3}>
              <div className='sub_heading_1'>Description</div>
            </Col>

            <Col xs={1}>
              <b>:</b>
            </Col>

            <Col xs={8}>
              {editRole.DESCRIPTION}
            </Col>

            <Col xs={12}>
              <ScrollPanel style={{width: '100%', height: '45vh', marginTop: '1%'}}>
                <CheckboxTree
                  nodes={list} checked={opts} 
                  expanded={selObj} showNodeIcon={true}
                  icons={{
                    check: <i className='pi pi-check-square' />,
                    uncheck: <i className="pi pi-stop" />,
                    halfCheck: <i className='pi pi-minus-circle' />,
                    expandClose: <i className='pi pi-chevron-right' />,
                    expandOpen: <i className='pi pi-chevron-down' />,
                    expandAll: <i className='pi pi-chevron-down' />,
                    collapseAll: <i className='pi pi-chevron-right' />,
                    parentClose: <i className='pi pi-folder' />,
                    parentOpen: <i className='pi pi-folder-open' />,
                    leaf: <i className='pi pi-file' />
                  }}
                  onExpand={(expanded) => setSObj(expanded)}
                />
              </ScrollPanel>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Delete Pop-up */}
      <Dialog 
        visible={del} style={{width: '35vw'}} 
        header={<div>Do you want to delete user Role ?</div>} 
        onHide={() => setDel(false)}
      >
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Container>
            <Row>
              <Col xs={6} style={{color: '#2F4F4F', textAlign: 'end'}} onClick={() => setDel(false)}>No</Col>
              <Col xs={6} style={{color: 'red', textAlign: 'start'}}>Yes</Col>
            </Row>
          </Container>
        </div>
      </Dialog>
    </div>
  );
};

export default RolesList;